<template>
  <div class="page-wrapper">
    <section-title :maxTitle="'集团介绍'" :minTitle="'Group Introduction'" />
    <div class="introdution-detail">
      <div class="introdution-img"></div>
      <div class="introdution-tes">
        <p>租我家集团是国内领先的集中式长租公寓赋能平台，通过数据驱动，为项目提供市场定位、设计施工、供应链支持、金融赋能、智能化升级和品牌运营等全链条服务。</p>
        <p>创立伊始已获得数家国内知名基金投资，累计管理物业规模60万平方米+，服务近百家合作公寓，总体动态入住率92%+。</p>
        <p>旗下“驿舍”、“怡居”、“君悦”和“美庭”品牌矩阵覆盖从员工集中居住空间到高端服务式公寓全客群长租需求。</p>
        <p>不论是物业包租、合作开发亦或托管运营，租我家均有成熟可靠的解决方案，我们期待成为您的专属物业管家。</p>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from '../components/sectionTitle'
export default {
  name: 'Introduction',
  data () {
    return {}
  },
  components: {
    SectionTitle,

  }
}
</script>

<style lang="less" scoped>
.page-wrapper {
  box-sizing: border-box;
  .introdution-detail {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .introdution-img {
      width: 45%;
      height: 85%;
      background-image: url('../../assets/images/introdution.jpg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .introdution-tes {
      width: 45%;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 40px;
      > p {
        margin: 40px 0px;
      }
    }
  }
}
@media (max-width: 1550px) {
  .introdution-tes {
    font-size: 14px !important;
    line-height: 30px !important;
    > p {
      margin: 30px 0px !important;
    }
  }
}
</style>
