import { render, staticRenderFns } from "./P-App.vue?vue&type=template&id=399e7949&scoped=true&"
import script from "./P-App.vue?vue&type=script&lang=js&"
export * from "./P-App.vue?vue&type=script&lang=js&"
import style0 from "./P-App.vue?vue&type=style&index=0&lang=css&"
import style1 from "./P-App.vue?vue&type=style&index=1&id=399e7949&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "399e7949",
  null
  
)

export default component.exports