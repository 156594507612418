<template>
  <div class="footer">
    <div class="footer-open" style="width: 100vw" ref="footer-open">
      <div class="application-wrapper">
        <i class="close-icon" @click="handleClose"></i>
        <div class="application">
          <div class="application-img mini-1"></div>
          <span class="application-name">租客端小程序</span>
        </div>
        <div class="application">
          <div class="application-img mini-2"></div>
          <span class="application-name">客户端小程序</span>
        </div>
        <div class="application">
          <div class="application-img mini-3"></div>
          <span class="application-name">租我家公众号</span>
        </div>
      </div>
      <div class="app-des">
        © 2021 深圳市租我家科技有限公司 版权所有 |
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          rel="noopener noreferrer"
        >粤ICP备16009271号</a>
      </div>
    </div>
    <div class="footer-close" style="width: 0px; overflow: hidden; " ref="footer-close">
      <div class="application">
        <div class="app-download">
          <span class="system">
            <i class="icon-win"></i>Win系统
          </span>
          <div class="btn-download">
            <a
              class="down-load-link"
              href="http://img.zuwoojia.com/Saas/Zuwoojia_SaaS_1.2.6.exe"
            >立即下载</a>
          </div>
        </div>
        <div class="app-download">
          <span class="system">
            <i class="icon-ios"></i>Mac系统
          </span>
          <div class="btn-download">
            <a
              class="down-load-link"
              href="http://img.zuwoojia.com/Saas/Zuwoojia_SaaS_1.2.6.dmg"
            >立即下载</a>
          </div>
        </div>
      </div>
      <div class="arrow" @click="handleOpen"></div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      showLong: true
    }
  },
  methods: {
    handleClose () {
      this.$refs['footer-open'].style.width = '0px'
      this.$refs['footer-open'].style.transition = 'all .5s linear'
      setTimeout(() => {
        this.$refs['footer-close'].style.width = '120px'
        this.$refs['footer-close'].style.transition = 'all .5s linear'
      }, 800)

    },
    handleOpen () {
      this.$refs['footer-close'].style.width = '0px'
      this.$refs['footer-close'].style.transition = 'all .5s linear'
      setTimeout(() => {
        this.$refs['footer-open'].style.width = '100vw'
        this.$refs['footer-open'].style.transition = 'all .5s linear'
      }, 500)


    }
  }
}
</script>
<style lang="less" scoped>
@import '@/assets/common.less';
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 120px;
  z-index: 999;
  .footer-open {
    position: absolute;
    overflow: hidden;
    z-index: 1000;
    height: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    .application-wrapper {
      width: 300px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 8px;
      .close-icon {
        position: absolute;
        z-index: 1000;
        top: 20px;
        right: 80px;
        width: 28px;
        height: 28px;
        .bg('../assets/images/close.png');
      }
      .application {
        width: 100px;
        text-align: center;
        .application-img {
          margin: 0 auto;
          width: 60px;
          height: 60px;
          &.mini-1 {
            background-image: url('../../assets/images/mini-1.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
          &.mini-2 {
            background-image: url('../../assets/images/mini-2.jpg');
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
          &.mini-3 {
            background-image: url('../../assets/images/mini-3.jpg');
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        }
        .application-name {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    .app-des {
      margin-top: 8px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #cdcdcd;
      > a {
        text-decoration: none;
        color: #cdcdcd;
      }
    }
  }
  .footer-close {
    z-index: 1000;
    position: absolute;
    height: 120px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0px 20px 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .application {
      background: #999999;
      width: 85px;
      height: 85px;
      .app-download {
        margin-top: 2px;
        width: 100%;
        .system {
          width: 85px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 17px;
          line-height: 17px;
          .font(12px,#ffffff,Medium,500);
        }
        .icon-win {
          display: inline-block;
          margin-right: 3px;
          width: 14px;
          height: 14px;
          .bg('../assets/images/win-icon.png');
        }
        .icon-ios {
          display: inline-block;
          width: 14px;
          height: 14px;
          margin-right: 3px;
          .bg('../assets/images/ios-icon.png');
        }
        .btn-download {
          text-align: center;
          .box(55px,19px);
          margin: 0 auto;
          border-radius: 9px;
          border: 1px solid #ffffff;
          .down-load-link {
            height: 19px;
            line-height: 19px;
            .font(12px,#ffffff,Medium,500);
            text-decoration: none;
          }
        }
      }
    }
    .arrow {
      width: 16px;
      height: 16px;
      background-image: url('../../assets/images/arrow.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>