<template>
  <div class="page-wrapper">
    <section-title :maxTitle="'金融支持'" :minTitle="'Financial Support'" />

    <div class="brand-financial">
      <div class="financial-title">“房东合伙人”长租公寓专项资金赋能</div>
      <div class="financial-item">
        <div class="financial-item-icon icon-1"></div>
        <div class="financial-item-des">
          <p class="des-title">业主“装修贷”</p>
          <p class="des-text">
            单一项目最高2,000万
            <br />无需抵押 最长5年
          </p>
        </div>
      </div>
      <div class="financial-item">
        <div class="financial-item-icon icon-2"></div>
        <div class="financial-item-des">
          <p class="des-title">承租方“经营宝”</p>
          <p class="des-text">
            单一项目最高1,000万有租约
            <br />即可申请 无需抵押 不用转租
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from '../components/sectionTitle'
export default {
  name: 'financial',
  data () {
    return {}
  },
  components: {
    SectionTitle,

  }
}
</script>

<style lang="less" scoped>
.page-wrapper {
  .brand-financial {
    width: 100%;
    height: 60%;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    .financial-title {
      text-align: center;
      width: 100%;
      font-size: 22px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    .financial-item {
      width: 49%;
      height: 230px;
      background: #f7f7f7;
      display: flex;
      align-items: center;
      justify-content: center;
      .financial-item-icon {
        width: 100px;
        height: 100px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        &.icon-1 {
          background-image: url('../../assets/images/financial-1.png');
        }
        &.icon-2 {
          background-image: url('../../assets/images/financial-2.png');
        }
      }

      .financial-item-des {
        margin-left: 50px;
        .des-title {
          height: 30px;
          font-size: 22px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 30px;
        }
        .des-text {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 40px;
        }
        @media (max-width: 1550px) {
          .des-title {
            font-size: 20px;
          }
          .des-text {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>