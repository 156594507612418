import Vue from 'vue'
import App from './P-App.vue'

import { Button, Select, Row, Col, Input, Tabs, Option, TabPane, Message } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Button);
Vue.use(Select);
Vue.use(Row);
Vue.use(Col);
Vue.use(Input);
Vue.use(Tabs);
Vue.use(Option);
Vue.use(TabPane);

Vue.prototype.$message = Message;

Vue.config.productionTip = false


new Vue({
    render: h => h(App),
}).$mount('#app')