<template>
  <div class="head-nav-wrapper">
    <div class="head-nav-logo"></div>
    <div class="head-nav-meus">
      <el-tabs v-model="active" @tab-click="handleClick">
        <el-tab-pane label="首页" name="home"></el-tab-pane>
        <el-tab-pane label="集团介绍" name="introduction"></el-tab-pane>
        <el-tab-pane label="品牌矩阵" name="brand"></el-tab-pane>
        <el-tab-pane label="服务介绍" name="serve"></el-tab-pane>
        <el-tab-pane label="金融支持" name="financial"></el-tab-pane>
        <el-tab-pane label="商务合作" name="contact"></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HeadNav',
  data () {
    return {

    }
  },
  props: {
    activeName: {
      type: String,
      default: 'home'
    }
  },
  computed: {
    active: {
      get () {
        return this.activeName
      },
      set () { },
    }
  },
  methods: {
    handleClick (tab) {
      this.$emit('change', tab.index)
    }
  }
}
</script>

<style lang="less" scoped>
.head-nav-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.3);

  z-index: 999;
  .head-nav-logo {
    margin-left: 60px;
    width: 130px;
    height: 38px;
    background-image: url('../../assets/images/logo.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .head-nav-meus {
    margin-right: 100px;
  }
}
</style>

<style lang="less">
.head-nav-meus {
  .el-tabs__active-bar {
    background-color: transparent !important;
    height: 0px;
  }
  .el-tabs__nav-wrap:after {
    background: transparent;
  }
  .el-tabs__header {
    margin: 0 0;
  }
  .el-tabs__item {
    width: 120px;
    padding: 0 40px;
    height: 28px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 28px;
  }
  .el-tabs__item.is-active {
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    transform: scale(1.1, 1.1);
  }
  .el-tabs__item:hover {
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    transform: scale(1.1, 1.1);

    cursor: pointer;
  }
  .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    padding-left: 40px !important;
  }
}
</style>