<template>
  <div class="page-wrapper">
    <section-title :maxTitle="'品牌矩阵'" :minTitle="'Brand matrix'" />
    <div class="brand-detail">
      <div class="brand-detail-item">
        <div class="brand-detail-position">
          <div class="brand-detail-title">驿舍</div>
          <div class="brand-detail-des">高品质员工住宿解决方案</div>
        </div>
      </div>
      <div class="brand-detail-item">
        <div class="brand-detail-position">
          <div class="brand-detail-title">怡居</div>
          <div class="brand-detail-des">智能化都市青年公寓</div>
        </div>
      </div>
      <div class="brand-detail-item">
        <div class="brand-detail-position">
          <div class="brand-detail-title">君悦</div>
          <div class="brand-detail-des">有温度的城市生活空间</div>
        </div>
      </div>
      <div class="brand-detail-item">
        <div class="brand-detail-position">
          <div class="brand-detail-title">美庭</div>
          <div class="brand-detail-des">精品轻奢服务公寓</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from '../components/sectionTitle'
export default {
  name: 'Brand',
  data () {
    return {}
  },
  components: {
    SectionTitle,
  }
}
</script>

<style lang="less" scoped>
@import '@/assets/common.less';
.page-wrapper {
  .brand-detail {
    .box(100%,80%);
    .flex(space-between,center);
    .brand-detail-item {
      .box(22%,90%);
      cursor: pointer;
      position: relative;
      .brand-detail-position {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 0;
        font-family: PingFangSC-Medium, PingFang SC;
        background-color: rgba(0, 0, 0, 0.2);
        color: #ffffff;
        font-weight: 500;
        .brand-detail-title {
          height: 33px;
          line-height: 33px;
          font-size: 24px;
        }
        .brand-detail-des {
          height: 22px;
          line-height: 22px;
          font-size: 16px;
        }
        @media (max-width: 1550px) {
          .brand-detail-title {
            font-size: 20px;
          }
          .brand-detail-des {
            font-size: 14px;
          }
        }
      }

      &:nth-child(1) {
        .bg('../assets/images/brand-1.jpg');
      }
      &:nth-child(2) {
        .bg('../assets/images/brand-2.jpg');
      }
      &:nth-child(3) {
        .bg('../assets/images/brand-3.jpg');
      }
      &:nth-child(4) {
        .bg('../assets/images/brand-4.jpg');
      }
    }
  }
}
</style>