<template>
  <div class="page-wrapper">
    <section-title :isLight="true" :maxTitle="'商务合作'" :minTitle="'Business Cooperation'" />

    <div class="contact-details-wrapper">
      <div class="contact-details">
        <div class="contact-title">租我家 您的专属物业管家</div>
        <div class="contact-item">
          <el-input type="text" v-model="name" placeholder="您的称呼"></el-input>
        </div>
        <div class="contact-item">
          <el-input type="tel" maxlength="11" v-model="phone" placeholder="联系方式"></el-input>
        </div>
        <div class="contact-item">
          <el-select class="contract-select" v-model="room" placeholder="物业体量">
            <el-option label="小于100间" value="0"></el-option>
            <el-option label="100-500间" value="1"></el-option>
            <el-option label="500间以上" value="2"></el-option>
          </el-select>
        </div>
        <div class="contact-item">
          <el-select class="contract-select" v-model="status" placeholder="物业状态">
            <el-option label="筹建中" value="0"></el-option>
            <el-option label="运营中" value="1"></el-option>
          </el-select>
        </div>
        <div class="contact-item">
          <el-select class="contract-select" v-model="attribution" placeholder="物业性质">
            <el-option label="承租" value="0"></el-option>
            <el-option label="自有" value="1"></el-option>
          </el-select>
        </div>
        <div class="contact-item">
          <el-select class="contract-select" v-model="position" placeholder="所在城市">
            <el-option label="深圳" value="0"></el-option>
            <el-option label="广州" value="1"></el-option>
            <el-option label="北京" value="2"></el-option>
            <el-option label="上海" value="3"></el-option>
            <el-option label="杭州" value="4"></el-option>
            <el-option label="其他" value="5"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="contact-submit">
      <el-button class="submit" @click="handleSubmit">联系我们</el-button>
    </div>
  </div>
</template>

<script>
import { postMessage } from '../../request'
import SectionTitle from '../components/sectionTitle'
export default {
  name: 'Brand',
  data () {
    return {
      name: '',
      phone: '',
      room: '',
      status: '',
      attribution: '',
      position: ''
    }
  },
  components: {
    SectionTitle,
  },
  methods: {
    handleSubmit () {
      if (!this.name || !this.phone || !this.status || !this.room || !this.attribution || !this.position) {
        this.$message.warning('请填写完整信息')
        return
      }
      if (this.phone.length !== 11) {
        this.$message.warning('请填写正确的手机号')
        return
      }

      postMessage({ name: this.name, phone: this.phone, amount: this.room, status: this.status, owner: this.attribution, address: this.position }).then(res => {
        if (res.code === 0) {
          this.$message.success(res.message)
        } else {
          this.$message.warning(res.message)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page-wrapper {
  .contact-details-wrapper {
    width: 100%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;

    .contact-details {
      width: 720px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      .contact-title {
        width: 100%;
        text-align: center;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 50px;
      }
      .contact-item {
        margin-bottom: 25px;
        width: 327px;
        height: 50px;
        .contract-select {
          width: 100%;
          height: 50px;
        }
      }
    }
  }
  .contact-submit {
    text-align: center;
    .submit {
      width: 217px;
      height: 48px;
      background-color: #e93a30;
      border-radius: 24px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      border: 1px solid #e93a30;
    }
  }
}
</style>

