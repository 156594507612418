<template>
  <div class="page-wrapper">
    <section-title :maxTitle="'服务介绍'" :minTitle="'Service Introduction'" />
    <div class="brand-detail">
      <div class="brand-detail-item" style="background: #f7f7f7;">
        <img class="brand-detail-icon" src="@/assets/images/serve-icon-1.png" alt="图标" />

        <div class="brand-detail-des">
          <p class="des-title">项目定位</p>
          <p class="des-text">
            26维数据模型 + 10年运营经验
            <br />精准定位客群，开业即满租，
            <br />全年无空置
          </p>
        </div>
      </div>
      <div class="brand-detail-item" style="background:#F2F2F2;">
        <img class="brand-detail-icon" src="@/assets/images/serve-icon-2.png" alt="图标" />
        <div class="brand-detail-des">
          <p class="des-title">供应链整合</p>
          <p class="des-text">
            定制化方案 + 模块化施工
            <br />整合供应链 全网最低价
            <br />消灭无效投资 缩短建设工期
          </p>
        </div>
      </div>
      <div class="brand-detail-item" style="background: #f7f7f7;">
        <img class="brand-detail-icon" src="@/assets/images/serve-icon-3.png" alt="图标" />
        <div class="brand-detail-des">
          <p class="des-title">智能公寓升级</p>
          <p class="des-text">
            可视化全平台管理终端 + 智能公
            <br />寓硬件部署管理省心 财务清晰
          </p>
        </div>
      </div>
      <div class="brand-detail-item" style="background: #F2F2F2;">
        <img class="brand-detail-icon" src="@/assets/images/serve-icon-4.png" alt="图标" />
        <div class="brand-detail-des">
          <p class="des-title">品牌化标准运营</p>
          <p class="des-text">
            50+项SOP运营标准
            <br />全方位专业运营提高租客满
            <br />意度、续租率
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from '../components/sectionTitle'
export default {
  name: 'Serve',
  data () {
    return {}
  },
  components: {
    SectionTitle,

  }
}
</script>

<style lang="less" scoped>
.page-wrapper {
  .brand-detail {
    width: 100%;
    height: 80%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    .brand-detail-item {
      width: 25%;

      text-align: center;
      .brand-detail-icon {
        padding-top: 50px;
        width: 88px;
        height: 88px;
      }
      .brand-detail-des {
        .des-title {
          line-height: 33px;
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          padding-bottom: 10px;
        }
        .des-text {
          height: 120px;
          padding-bottom: 40px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 30px;
        }
      }
    }
  }
}
@media (max-width: 1550px) {
  .brand-detail-icon {
    padding-top: 30px !important;
    width: 66px !important;
    height: 66px !important;
  }
  .brand-detail-des {
    .des-title {
      font-size: 20px !important;
    }
    .des-text {
      padding-bottom: 20px !important;
      font-size: 14px !important;
    }
  }
}
</style>