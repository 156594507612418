<template>
  <div id="app-content">
    <div class="fullPage" ref="fullPage">
      <head-nav :activeName="navString" @change="changePage" />
      <div
        class="fullPageContainer"
        ref="fullPageContainer"
        @mousewheel="mouseWheelHandle"
        @DOMMouseScroll="mouseWheelHandle"
      >
        <div class="section section1"></div>
        <div class="section section2">
          <introduction />
        </div>
        <div class="section section3">
          <brand />
        </div>
        <div class="section section4">
          <serve-introduction />
        </div>
        <div class="section section5">
          <financial />
        </div>
        <div class="section section6">
          <contract />
        </div>
      </div>
      <footer-nav />
    </div>
  </div>
</template>

<script>
import HeadNav from './page/HeadNav'
import Brand from './page/brand'
import ServeIntroduction from './page/serve'
import Financial from './page/Financial'
import Introduction from './page/Introduction'
import Contract from './page/contract'
import FooterNav from './page/Footer'


export default {
  name: 'App',
  data () {
    return {
      oldTime: Date.now(),
      timer: null,
      fullpage: {
        current: 1, // 当前的页面编号
        isScrolling: true, // 是否在滚动,是为了防止滚动多页，需要通过一个变量来控制是否滚动
        deltaY: 0 // 返回鼠标滚轮的垂直滚动量，保存的鼠标滚动事件的deleteY,用来判断是往下还是往上滚
      },
      HeadNavList: ['home', 'introduction', 'brand', 'serve', 'financial', 'contact']
    };
  },
  mounted () {
    window.onresize = () => {
      window.location.reload();
    }

  },
  computed: {
    navString () {
      return this.HeadNavList[this.fullpage.current - 1]
    }
  },
  components: {
    HeadNav,
    Brand,
    ServeIntroduction,
    Introduction,
    Contract,
    FooterNav,

    Financial,
  },
  methods: {
    next () { // 往下切换
      let len = 6; // 页面的个数
      if (this.fullpage.current + 1 <= len) { // 如果当前页面编号+1 小于总个数，则可以执行向下滑动
        this.fullpage.current += 1; // 页面+1
        this.move(this.fullpage.current); // 执行切换
      }
    },
    pre () {// 往上切换
      if (this.fullpage.current - 1 > 0) { // 如果当前页面编号-1 大于0，则可以执行向下滑动
        this.fullpage.current -= 1;// 页面+1
        this.move(this.fullpage.current);// 执行切换
      }
    },
    move (index) {
      this.directToMove(index); //执行滚动
    },

    directToMove (index) {
      let height = this.$refs["fullPage"].clientHeight; //获取屏幕的宽度
      let scrollPage = this.$refs["fullPageContainer"];  // 获取执行tarnsform的元素
      let scrollHeight; // 计算滚动的告诉，是往上滚还往下滚
      scrollHeight = -(index - 1) * height + "px";
      scrollPage.style.transform = `translateY(${scrollHeight})`;
      this.fullpage.current = index;
    },
    changePage (val) {
      this.move(Number(val) + 1)
    },
    mouseWheelHandle (event) { // 监听鼠标监听
      this.oldTime = Date.now()

      if (this.fullpage.isScrolling) {
        this.fullpage.isScrolling = false
        let e = event.originalEvent || event;
        this.fullpage.deltaY = e.deltaY || e.detail; // Firefox使用detail
        if (this.fullpage.deltaY > 0) {
          this.next();
        } else if (this.fullpage.deltaY < 0) {
          this.pre();
        }
      }

      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.fullpage.isScrolling = true
      }, 300)
    }
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  min-width: 1200px;
  min-height: 675px;
  box-sizing: border-box;
}
#app-content {
  position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>

<style lang="less" scoped>
@import '@/assets/common.less';
.fullPage {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #ffffff;
}
.fullPageContainer {
  width: 100vw;
  height: 100vh;
  transition: all linear 0.5s;
}
.section {
  width: 100vw;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
.section1 {
  position: relative;
  .bg('../assets/images/home-new.jpg');
}
.section1 .secction1-content {
  color: #fff;
  text-align: center;
  position: absolute;
  top: 40%;
  right: 0;
  left: 0;
}
.secction1-content h1 {
  font-size: 40px;
  padding-bottom: 30px;
}
.secction1-content p {
  font-size: 20px;
}
.section2 {
  background-color: #ffffff;
}
.section3 {
  background-color: #ffffff;
}
.section4 {
  background-color: #ffffff;
}
.section5 {
  background-color: #ffffff;
}
.section6 {
  background-color: #ffffff;
  .bg('../assets/images/home-end.jpg');
}
</style>
